@tailwind base;
@tailwind components;
@tailwind utilities;

.semibold {
  font-weight: 600 !important;
}

.primaryColor {
  color: #f7815c;
}

.secondaryColor {
  color: #221735;
}

.violetColor {
  color: #cea3e2 !important;
}

.pinkColor {
  color: #cda3e3 !important;
}

.indicatorIcon > * .indicatorIndex {
  background-color: #dcd9e1 !important;
}

.activeIndicatorIcon > * .indicatorIndex {
  background-color: #f7815c !important;
}

.spaceLine {
  padding-left: 30px;
}

.primary-underline {
  border-bottom: 2px solid #f7815c;
}

.leading-height {
  line-height: 1.75rem;
}

.box-container {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}

.underlineText {
  text-decoration: underline;
}

h1.customHeader {
  font-size: 23px;
  line-height: 36px;
  letter-spacing: 0.25px;
  text-transform: uppercase;
}

h2.customHeader {
  font-size: 49px;
  line-height: 60px;
  letter-spacing: -1.5px;
}

.custom-shadow {
  position: relative;
}
.custom-shadow:after {
  box-shadow: 12px 0 12px -12px rgba(190, 190, 190, 0.44) inset;
  content: " ";
  height: 100%;
  position: absolute;
  top: 0;
  right: -12px;
  width: 12px;
}
.customScroll::-webkit-scrollbar {
  width: 15rem;
}
.customScroll::-webkit-scrollbar-track {
  background-color: transparent;
}
.customScroll::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 15rem;
  border: 6px solid transparent;
  background-clip: content-box;
}
.customScroll::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.8);
}
.headerFade {
  display: grid;
}
.headerFade * {
  grid-area: 1 / 1;
}
.headerFade #fade {
  animation-name: fade;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 4s;
  animation-direction: alternate;
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
